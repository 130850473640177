import axios from "axios";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import PopUpModal from "../PopUpModal";
import { getViewRoom } from "../../globalAPI/Api";
import { useLocation } from "react-router-dom";
const ViewRoomForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  // console.log("state", state);
  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames) || [];

  // Set the initial state based on the branchNameArray
  const [selectedHostel, setSelectedHostel] = useState(
    (state && state) || branchNameArray[0] || ""
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const getViewRoom = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/rooms/getAllNewRoom/${selectedHostel}`
    );
  };

  const { data, isLoading, isError, refetch } = useQuery(
    ["getViewRoom", selectedHostel],
    getViewRoom,
    {
      enabled: !!selectedHostel, // Ensure the query runs only if selectedHostel is not empty
    }
  );

  let details = data && data?.data;
  // console.log("DETAILS", details);
  useEffect(() => {
    const applyFilterAndSearch = () => {
      if (!Array.isArray(details)) {
        setFilteredData([]); // Ensure filteredData is cleared if data is not an array
        return;
      }

      const normalizedSearchTerm =
        searchTerm?.toLowerCase().replace(/\s+/g, "") || ""; // Normalize search term by removing spaces
      const result = details.filter((item) => {
        const matchesFilter = filter
          ? item?.status.toLowerCase() === filter.toLowerCase()
          : true;

        // Normalize itemRoomNo by removing spaces
        const itemRoomNo = (item?.roomNo ?? "")
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "");

        // Check if normalized itemRoomNo starts with normalized searchTerm
        const matchesSearch = normalizedSearchTerm
          ? itemRoomNo.startsWith(normalizedSearchTerm)
          : true;

        return matchesFilter && matchesSearch;
      });

      setFilteredData(result);
    };

    applyFilterAndSearch();
  }, [details, filter, searchTerm]);
  useEffect(() => {
    if (state && state) {
      setSelectedHostel(state);
      setValue("selectHostel", state); // Update the react-hook-form field
    }
  }, [state, setValue]);

  return (
    <>
      <div className="row pt-2 pb-3 px-2 m-0 new-register-heading-block">
        <div className="col-12 col-md-6 d-flex  justify-content-between align-items-center my-1 px-1">
          <div className="new-register-title-block">
            <img
              src="/assets/images/arrow-blue.png"
              alt="no-image"
              className="arrow-img-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="register-title pt-2">VIEW ROOM</h5>
          </div>

          <Controller
            name="selectHostel"
            control={control}
            defaultValue={selectedHostel}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange } }) => (
              <select
                value={selectedHostel}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSelectedHostel(newValue); // Update local state
                  onChange(newValue); // Update react-hook-form field
                }}
                className={`selectDropDown ${
                  errors.selectHostel ? "error" : ""
                }`}
              >
                <option value="">Branch Name</option>
                {branchNameArray.map((branchName) => (
                  <option key={branchName} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            )}
          />
        </div>
        <div className="col-12 col-md-6  d-flex justify-content-between  align-items-center gap-2 px-1">
          <select
            className="view-hostel-status"
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
          >
            <option value="">Hostel Status</option>
            <option value="Available">Available</option>
            <option value="Unavailable">Unavailable</option>
          </select>
          <button
            className="new-room-btn"
            onClick={() => {
              navigate("/newRoom", { state: selectedHostel });
            }}
          >
            NEW ROOM
          </button>
          <input
            type="search"
            className="view-bill-search"
            placeholder="search by roomno"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="view-room-block">
        <div className="view-room-main-block">
          {filteredData && filteredData?.length > 0 ? (
            filteredData?.map((d, i) => {
              return (
                <>
                  <div class="card  card-block" key={i}>
                    <div className="text-end">
                      <img
                        src="/assets/images/edit-icon.png"
                        className="--image-click"
                        alt="no-image"
                        onClick={() => {
                          navigate("/editRoom", { state: d });
                        }}
                      />
                    </div>
                    <div
                      class="card-body card-content-block"
                      onClick={() => {
                        navigate("/viewBeds", { state: d });
                      }}
                    >
                      <p className="card-content-para d-flex gap-3 align-items-center flex-wrap pb-1">
                        <span class="card-title">Room No : {d?.roomNo}</span>
                        <span className="card-title">
                          Sharing : {d?.noOfBeds}
                        </span>
                      </p>

                      <p className="card-content-ac-type  d-flex gap-3 align-items-center pb-1">
                        <span class="card-title"> BedRoom : {d?.bedRoom}</span>
                        <span className="card-title">
                          Kitchen : {d?.kitchen}
                        </span>
                      </p>
                      <p className="card-content-para pb-2">Hall : {d?.hall}</p>
                      {d?.status == "Available" || d?.status == "available" ? (
                        <p
                          className="hostel-status"
                          style={{ color: "greenyellow" }}
                        >
                          {d?.status}
                        </p>
                      ) : (
                        <p
                          className="hostel-status"
                          style={{ color: "yellow" }}
                        >
                          {d?.status}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <>
              <p className="no-result">NO ROOM AVAILABLE !!!</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewRoomForm;
