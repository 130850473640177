import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import PopUpModal from "../components/PopUpModal";
import { Controller, useForm } from "react-hook-form";
import Sidebar from "../components/Sidebar";
import moment from "moment/moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { getViewRegister } from "../globalAPI/Api";
// import downloadIcon from "../../public/assets/images/download.png"
import { useLocation } from "react-router-dom";

const ViewRegister = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const queryClient = useQueryClient();
  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null);
  const [deletePopup, setDeletePopUp] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const itemsPerPage = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [totalcount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [InactiveCount, setInactiveCount] = useState(0);
  const storedBranchNames = localStorage.getItem("branchNames");

  const { state } = useLocation();

  const branchNameArray = JSON.parse(storedBranchNames) || [];

  // Set the initial state based on the branchNameArray
  const [selectedHostel, setSelectedHostel] = useState(
    (state && state) || branchNameArray[0] || ""
  );
  const getViewRegister = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/newregister/getAllNewRegister/${selectedHostel}`
    );
  };
  const { data, isLoading, isError, refetch } = useQuery(
    ["getViewRegister", selectedHostel],
    getViewRegister,
    {
      enabled: !!selectedHostel, // Ensure the query runs only if selectedHostel is not empty
    }
  );

  let details = data && data?.data;
  // console.log("DETAILS", details);

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const applyFilterAndSearch = () => {
      if (!details) {
        setFilteredData([]);
        return;
      }

      // Normalize searchTerm by converting to lowercase and removing spaces
      const normalizedSearchTerm =
        searchTerm?.toLowerCase().replace(/\s+/g, "") || "";

      const result =
        details &&
        details?.filter((item) => {
          // Ensure that the filter criteria is applied
          const matchesFilter = filter ? item?.status === filter : true;

          // Normalize item fields to lowercase and remove spaces for comparison
          const itemRoomNo = (item?.roomNo ?? "")
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "");
          const itemName = (item?.name ?? "")
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "");

          // Check if any of the normalized fields match the normalized searchTerm
          const matchesSearch =
            !searchTerm ||
            itemRoomNo.startsWith(normalizedSearchTerm) ||
            itemName.startsWith(normalizedSearchTerm);

          return matchesFilter && matchesSearch;
        });

      setFilteredData(result);
    };

    applyFilterAndSearch();
  }, [details, filter, searchTerm]);

  // Pagination logic
  const totalPages = Math.ceil(
    filteredData && filteredData?.length / itemsPerPage
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination handlers
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages || totalPages === 0;

  const handlePrevPage = () => {
    if (!isPrevDisabled) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (!isNextDisabled) setCurrentPage(currentPage + 1);
  };
  const deleteItem = async (id) => {
    await axios.delete(
      `${process.env.REACT_APP_HOST}/newregister/${selectedHostel}/${id}`
    );
  };

  const mutation = useMutation(deleteItem, {
    onSuccess: () => {
      // queryClient.invalidateQueries("getViewBill");
      refetch();

      setDeletePopUp(1); // Show success popup
    },
    onError: (err) => {
      console.log("Error", err);
    },
  });

  const handleDelete = (id) => {
    mutation.mutate(id);
    setAddSubTicketPopUp(null); // Close confirmation popup
  };
  useEffect(() => {
    // Initialize totals
    let totalreg = 0;
    let activeCount = 0;
    let inactiveCount = 0;

    // Compute totals
    filteredData?.forEach((item) => {
      totalreg += 1;
      if (item?.status == "Active") {
        activeCount += 1;
      } else if (item?.status == "Inactive") {
        inactiveCount += 1;
      }
    });

    // Update state with computed totals
    setTotalCount(totalreg);
    setActiveCount(activeCount);
    setInactiveCount(inactiveCount);
  }, [currentItems]);

  const downloadExcel = () => {
    // Prepare empty data if there's no valid data to export
    const exportData =
      filteredData && filteredData.length > 0 ? filteredData : data;

    const tableDatas =
      exportData && exportData?.length > 0
        ? exportData?.map((data, index) => ({
            "S.NO": index + 1,
            NAME: data?.name || "NULL",
            "ROOM NO": data?.roomNo || "NULL",
            "MOBILE NUMBER": data?.mobileNo || "NULL",
            "PROFESSION TYPE": data?.proffesionType || "NULL",
            "AADHAR NUMBER": data?.aadharNumber || "NULL",
            "EMAIL ID": data?.emailId || "NULL",
            "DATE OF BIRTH": data?.dateOfBirth || "NULL",
            "DATE OF JOINING": data?.dateOfJoining || "NULL",
            "PARENT/GUARDIAN": data?.parentialOrGuardian || "NULL",
            "GUARDIAN NUMBER": data?.familyNumber || "NULL",
            "FOOD OPTION": data?.selectFoodOption || "NULL",
            "ADVANCE PAYMENT": data?.advanceAmount || "NULL",
            "RENT FOR MONTH": data?.rentForMonth || "NULL",
            ADDRESS: data?.address || "NULL",
            STATUS: data?.status || "NULL",
            "DATE OF LEAVING": data?.dateOfLeaving || "NULL",
          }))
        : []; // Default to an empty array

    // Create a new workbook and add an empty sheet if there's no data
    const workbook = XLSX.utils.book_new();
    const tableWorksheet = XLSX.utils.json_to_sheet(
      tableDatas?.length > 0 ? tableDatas : [{}],
      {
        skipHeader: false,
      }
    );

    // Optionally set headers even if no data
    if (tableDatas?.length === 0) {
      XLSX.utils.sheet_add_aoa(tableWorksheet, [
        [
          "S.NO",
          "NAME",
          "ROOM NO",
          "MOBILE NUMBER",
          "PROFESSION TYPE",
          "AADHAR NUMBER",
          "EMAIL ID",
          "DATE OF BIRTH",
          "DATE OF JOINING",
          "PARENT/GUARDIAN",
          "GUARDIAN NUMBER",
          "FOOD OPTION",
          "ADVANCE PAYMENT",
          "RENT FOR MONTH",
          "ADDRESS",
          "STATUS",
          "DATE OF LEAVING",
        ],
      ]);
    }

    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "View Register.xlsx");
  };
  useEffect(() => {
    if (state && state) {
      setSelectedHostel(state);
      setValue("selectHostel", state); // Update the react-hook-form field
    }
  }, [state, setValue]);
  return (
    <>
      <section className="viewBillMainSection">
        <div className="container-fluid ">
          <div className="row p-0">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 p-0 m-0">
              <div className="row pt-2 pb-3 px-2 m-0 new-register-heading-block">
                <div className="col-12 col-md-6 d-flex  justify-content-between align-items-center my-1 px-1">
                  <div className="new-register-title-block">
                    <img
                      src="/assets/images/arrow-blue.png"
                      alt="no-image"
                      className="arrow-img-icon"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    <h5 className="register-title pt-2">VIEW REGISTER</h5>
                  </div>
                  <Controller
                    name="selectHostel"
                    control={control}
                    defaultValue={selectedHostel}
                    rules={{ required: "This field is required" }}
                    render={({ field: { onChange } }) => (
                      <select
                        value={selectedHostel}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setSelectedHostel(newValue); // Update local state
                          onChange(newValue); // Update react-hook-form field
                        }}
                        className={`selectDropDown ${
                          errors.selectHostel ? "error" : ""
                        }`}
                      >
                        <option value="">Branch Name</option>
                        {branchNameArray.map((branchName) => (
                          <option key={branchName} value={branchName}>
                            {branchName}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>
                <div className="col-12    col-md-6  d-flex justify-content-between  align-items-center gap-2 px-1 mt-1">
                  <div
                    className="overview-download-block"
                    onClick={() => downloadExcel()}
                  >
                    <div className="search-icon">
                      <img
                        src="/assets/images/download.png"
                        alt="no-image"
                        className="crm-image"
                      />
                    </div>
                    <button className="overview-btn">DOWNLOAD</button>
                  </div>

                  <select
                    className="view-bill-status"
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                  >
                    <option value="">Filter</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  <input
                    type="search"
                    className="view-bill-search"
                    placeholder="search by roomno , name "
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>

              <div className="status-bar-block">
                <div className="status-row-bar">
                  <div className="bill-status-bar --first-bar">
                    <p className="bill-status-content">ACTIVE</p>
                    <p className="bill-status-count">{activeCount} Person</p>
                  </div>
                  <div className="bill-status-bar">
                    <p className="bill-status-content">INACTIVE</p>
                    <p className="bill-status-count">{InactiveCount} Person</p>
                  </div>
                  <div className="bill-status-bar --last-bar">
                    <p className="bill-status-content">TOTAL</p>
                    <p className="bill-status-count">{totalcount} Person</p>
                  </div>{" "}
                </div>
              </div>
              <div className="view-register-table-block">
                <div className="view-bill-main-block">
                  <div className="table-responsive table-container">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="view-bill-head --first-head">NAME</th>
                          <th className="view-bill-head">ROOM NO</th>
                          {/* <th className="view-bill-head">BED NO</th> */}
                          <th className="view-bill-head">MOBILE NO</th>
                          <th className="view-bill-head">PROFESSION TYPE</th>
                          <th className="view-bill-head">AADHAR NUMBER</th>

                          <th className="view-bill-head">EMAILID</th>
                          <th className="view-bill-head">DATE OF BIRTH</th>
                          <th className="view-bill-head">DATE OF JOINING</th>
                          <th className="view-bill-head">PARENT/GUARDIAN</th>
                          <th className="view-bill-head">GUARDIAN NUMBER</th>
                          <th className="view-bill-head">FOOD OPTION</th>
                          <th className="view-bill-head">ADVANCE PAYMENT</th>
                          <th className="view-bill-head">RENT FOR MONTH</th>
                          <th className="view-bill-head">ADDRESS</th>
                          <th className="view-bill-head">STATUS</th>
                          <th className="view-bill-head">DATE OF LEAVING</th>
                          <th className="view-bill-head --last-head">EDIT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems && currentItems?.length > 0 ? (
                          currentItems?.map((item, index) => (
                            <tr key={index}>
                              <td className="view-bill-data">{item?.name}</td>
                              <td className="view-bill-data">{item?.roomNo}</td>
                              {/* <td className="view-bill-data">{item?.bedNo}</td> */}
                              <td className="view-bill-data">
                                {item?.mobileNo}
                              </td>
                              <td className="view-bill-data">
                                {item?.proffesionType}
                              </td>
                              <td className="view-bill-data">
                                {item?.aadharNumber}
                              </td>
                              <td className="view-bill-data">
                                {item?.emailId}
                              </td>
                              <td className="view-bill-data">
                                {item?.dateOfBirth}
                              </td>
                              <td className="view-bill-data">
                                {item?.dateOfJoining}
                              </td>
                              <td className="view-bill-data">
                                {item?.parentialOrGuardian}
                              </td>
                              <td className="view-bill-data">
                                {item?.familyNumber}
                              </td>
                              <td className="view-bill-data">
                                {item?.selectFoodOption}
                              </td>
                              <td className="view-bill-data">
                                {item?.advanceAmount}
                              </td>
                              <td className="view-bill-data">
                                {item?.rentForMonth}
                              </td>
                              <td className="view-bill-data">
                                {item?.address}
                              </td>
                              <td className="view-bill-data">{item?.status}</td>
                              <td className="view-bill-data">
                                {item?.status == "Inactive"
                                  ? moment(item?.selectHostel).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "---"}
                              </td>

                              <td className="view-bill-data">
                                <img
                                  src="/assets/images/edit-icon.png"
                                  className="--image-click"
                                  alt="no-image"
                                  onClick={() =>
                                    navigate("/editRegister", { state: item })
                                  }
                                />
                                <img
                                  src="/assets/images/delete-icon.png"
                                  className="--image-click"
                                  alt="no-image"
                                  onClick={() => {
                                    setAddSubTicketPopUp(true);
                                    setDeleteId(item?.id);
                                  }}
                                />
                                {/* <img
                                  src="/assets/images/bill.png"
                                  className="--image-click"
                                  alt="no-image"
                                  onClick={() => {
                                    navigate("/newBill", { state: item });
                                  }}
                                /> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="17">No results found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between pt-2 px-1">
                    <button
                      onClick={handlePrevPage}
                      disabled={isPrevDisabled}
                      className="arrow-btn"
                    >
                      &lt;
                    </button>
                    <button
                      onClick={handleNextPage}
                      disabled={isNextDisabled}
                      className="arrow-btn"
                    >
                      &gt;
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PopUpModal isOpen={addSubTicketPopUp === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => setAddSubTicketPopUp(null)}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="popupDeleteUser m-0 px-1">
            Are you sure you want to delete the user detail?
          </p>
          <div className="d-flex justify-content-center align-items-center gap-2">
            <button
              onClick={() => {
                handleDelete(deleteId);
              }}
            >
              {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "YES"}
            </button>
            <button onClick={() => setAddSubTicketPopUp(null)}>NO</button>
          </div>
        </div>
      </PopUpModal>

      <PopUpModal isOpen={deletePopup === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => setDeletePopUp(null)}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>
        <div className="pt-2 d-flex flex-column justify-content-center align-items-center">
          <p className="popupDeleteUser m-0 p-0">
            User has been deleted successfully.
          </p>
          <button
            onClick={() => {
              setDeletePopUp(null);
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default ViewRegister;
